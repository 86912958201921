<template>
  <div class="empty-screen">
    <span class="icon">
      <font-awesome-icon :icon="icon" size="6x" />
    </span>

    <h2>
      <slot name="title"></slot>
    </h2>

    <p>
      <slot name="description"></slot>
    </p>

    <custom-button
      variant="primary"
      type="button"
      @click="$emit('ctaClick', $event)"
    >
      <slot name="btnText"></slot>
      <font-awesome-icon slot="icon" icon="plus" />
    </custom-button>
  </div>
</template>

<script>
import CustomButton from "../components/CustomButton.vue";

export default {
  name: "EmptyScreen",

  components: { CustomButton },

  props: {
    icon: {
      default: () => ({}),
      type: Object
    }
  }
};
</script>

<style scoped>
.empty-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.icon {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grayColor2);
  background-color: var(--grayColor1);
  border-radius: 50%;
}

h2 {
  margin-top: var(--lengthLg1);
  color: var(--fontColor2);
  font-weight: var(--medium);
}

p {
  max-width: 75ch;
  margin: var(--lengthSm2) auto var(--lengthMd3);
  color: var(--fontColor3);
  font-weight: var(--medium);
}
</style>
