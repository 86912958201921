<template>
  <section class="custom-section">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "CustomSection"
};
</script>

<style scoped>
.custom-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
  padding: var(--lengthMd3);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}
</style>
