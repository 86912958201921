<template>
  <label class="radio" :class="{ 'radio--checked': isChecked }">
    <input
      type="radio"
      :value="option"
      :checked="isChecked"
      :name="name"
      @change="onChange(option)"
      class="radio__input"
    />

    {{
      typeof option === "object"
        ? option.value || option.name || option.text
        : option
    }}
  </label>
</template>

<script>
export default {
  name: "RadioButton",

  model: {
    prop: "picked",
    event: "change"
  },

  props: {
    option: [Object, String],
    name: String,
    picked: [Object, String]
  },

  computed: {
    isChecked() {
      return typeof this.option === "object"
        ? this.option.id === this.picked.id
        : this.option === this.picked;
    }
  },

  methods: {
    onChange(option) {
      this.$emit("change", option);
    }
  }
};
</script>

<style scoped>
.radio {
  display: inline-block;
  padding: var(--lengthSm2);
  font-weight: var(--medium);
  border-radius: var(--lengthSm1);
  box-shadow: 0 0 0 1px var(--grayColor2);
  cursor: pointer;
  transition: background-color 300ms, box-shadow 300ms;
}

.radio:not(.radio--checked):hover,
.radio:not(.radio--checked):focus {
  background-color: var(--grayColor1);
  outline: none;
}

.radio__input {
  display: none;
}

.radio--checked {
  color: var(--fontColor1);
  box-shadow: 0 0 0px 2px var(--mainColor1);
}
</style>
