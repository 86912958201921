<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="stepper"
  >
    <input
      type="number"
      :value="value"
      :id="id"
      class="field"
      :class="{ 'field--invalid': errors.length }"
      @input="updateInput($event.target.value)"
      @wheel.prevent="onWheelInput"
      ref="input"
    />

    <label
      v-if="label"
      :for="id"
      class="label"
      :class="{ 'label--invalid': errors.length }"
    >
      <span>{{ label || name }}</span>
      <span>{{ required ? " *" : "" }}</span>
    </label>

    <div class="buttons">
      <button class="button" type="button" @click="updateInput(value - 1)">
        <font-awesome-icon icon="minus" size="xs" />
      </button>

      <button class="button" type="button" @click="updateInput(value + 1)">
        <font-awesome-icon icon="plus" size="xs" />
      </button>
    </div>

    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "NumberInput",

  components: { ValidationProvider },

  props: {
    value: {
      type: Number,
      default: 0
    },

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    id: String,
    label: String
  },

  methods: {
    updateInput(newValue) {
      this.$refs.input.focus();

      const value = parseInt(newValue);

      if (isNaN(value)) return;

      this.$emit("input", value);
    },

    onWheelInput(e) {
      return e.deltaY > 0
        ? this.updateInput(this.value - 1)
        : this.updateInput(this.value + 1);
    }
  }
};
</script>

<style scoped>
.stepper {
  position: relative;
  flex-grow: 1;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: var(--lengthMd1);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--mainColor1);
}

.label--invalid,
.error {
  color: var(--dangerColor);
}

.error {
  margin-top: var(--lengthSm1);
  font-size: 0.75rem;
}

.field {
  width: 100%;
  padding: var(--lengthMd1) var(--lengthSm2) var(--lengthSm3);
  color: var(--fontColor1);
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
  -moz-appearance: textfield;
}

.field--invalid {
  border-color: var(--dangerColor);
}

.field:not(.field--invalid):focus {
  border-color: var(--mainColor1);
}

.field::-webkit-outer-spin-button,
.field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: var(--lengthSm2);
}

.button {
  justify-content: center;
  margin-left: 4px;
  padding: var(--lengthSm2);
  color: var(--fontColor2);
  border-radius: 4px;
  transition: background-color 150ms, color 150ms;
}

.button:hover {
  color: var(--fontColor1);
  background-color: var(--grayColor1);
}
</style>
