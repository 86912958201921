<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
    class="radio-group"
  >
    <h4 v-if="label" class="label" :class="{ 'label--invalid': errors.length }">
      {{ label || name }}
    </h4>

    <div class="options">
      <radio-button
        v-for="(option, index) in options"
        :key="index"
        :name="groupName"
        :option="option"
        :picked="picked"
        @change="$emit('change', $event)"
      />
    </div>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import RadioButton from "./RadioButton.vue";

export default {
  name: "RadioButtonGroup",

  model: {
    prop: "picked",
    event: "change"
  },

  components: { RadioButton, ValidationProvider },

  props: {
    options: Array,

    picked: [Object, String],

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    groupName: String,
    label: String
  }
};
</script>

<style scoped>
.label {
  margin-bottom: var(--lengthSm3);
  color: var(--fontColor1);
  font-size: var(--lengthMd1);
  font-weight: var(--medium);
}

.options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.label--invalid,
.error {
  color: var(--dangerColor);
}

.error {
  margin-top: var(--lengthSm1);
  font-size: 0.75rem;
}
</style>
