<template>
  <multi-step-form
    :steps="steps"
    :currentStep="currentStep"
    @changeStep="currentStep = $event"
    @submit="onSubmit"
    @cancel="onCancel"
  >
    <template v-if="currentStep === 0">
      <custom-input
        label="Nombre"
        v-model="category.name"
        rules="required|minmax:1,50"
      />

      <radio-button-group
        v-model="category.dataType"
        groupName="data-type"
        label="Tipo"
        :options="typeOptions"
        @change="resetOptions"
        rules="required"
      />

      <radio-button-group
        v-if="category.dataType.id === 'Number'"
        label="Tipo de Número"
        :options="numberStyleOptions"
        v-model="category.numberStyle"
        @change="resetOptions"
        rules="required"
      />
    </template>

    <template v-if="currentStep === 1">
      <div class="btn-container">
        <custom-button type="button" variant="secondary" @click="addOption">
          <font-awesome-icon icon="plus" slot="icon" />
          Agregar opci&oacute;n
        </custom-button>
      </div>

      <template v-if="category.dataType.id === 'String'">
        <Option
          v-for="(option, index) in category.options"
          :key="option.id"
          :label="`Opción ${index + 1}`"
        >
          <custom-input v-model="option.value" rules="required|minmax:1,50" />
        </Option>
      </template>

      <template
        v-else-if="
          category.dataType.id === 'Number' &&
            category.numberStyle.id === 'list'
        "
      >
        <Option
          v-for="(option, index) in category.options"
          :key="option.id"
          :label="`Opción ${index + 1}`"
        >
          <number-input
            v-model="option.value"
            rules="required|positive|numeric"
          />
        </Option>
      </template>

      <template
        v-else-if="
          category.dataType.id === 'Number' &&
            category.numberStyle.id === 'range'
        "
      >
        <Option
          v-for="(option, index) in category.options"
          :key="option.id"
          :label="`Opción ${index + 1}`"
        >
          <range-input v-model="option.value" />
        </Option>
      </template>
    </template>
  </multi-step-form>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

import CustomInput from "../components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import NumberInput from "../components/NumberInput.vue";
import MultiStepForm from "../components/MultiStepForm.vue";
import RadioButtonGroup from "../components/RadioButtonGroup.vue";
import RangeInput from "../components/RangeInput.vue";
import Option from "../components/Option.vue";

export default {
  name: "EditCategory",

  components: {
    CustomInput,
    CustomButton,
    NumberInput,
    MultiStepForm,
    RadioButtonGroup,
    RangeInput,
    Option
  },

  props: {
    categoryToEdit: Object
  },

  data: () => ({
    category: {
      name: "",
      dataType: {},
      options: [],
      numberStyle: {}
    },

    typeOptions: [
      { id: "String", value: "Texto" },
      { id: "Number", value: "Número" }
    ],

    numberStyleOptions: [
      { id: "range", value: "Rango" },
      { id: "list", value: "Listado" }
    ],

    currentStep: 0,
    steps: ["Información Básica", "Opciones"],

    isEditing: false
  }),

  computed: {
    ...mapState(["categories"])
  },

  methods: {
    ...mapMutations(["setAlert"]),
    ...mapActions(["fetchCategories", "updateCategory", "addCategory"]),

    resetOptions() {
      this.category.options = [];
      this.addOption();
    },

    addOption() {
      const { dataType, numberStyle, options } = this.category;

      if (
        dataType.id === "String" ||
        (dataType.id === "Number" && numberStyle.id === "range")
      )
        options.push({ value: "" });
      else if (dataType.id === "Number" && numberStyle.id === "list")
        options.push({ value: 0 });
    },

    deleteOption(index) {
      if (this.category.options.length <= 1) return;
      this.category.options.splice(index, 1);
    },

    async onSubmit() {
      try {
        if (!this.isEditing) await this.addCategory(this.category);
        else await this.updateCategory(this.category);

        this.setAlert({
          state: "success",
          message: this.isEditing
            ? "Categoría actualizada"
            : "Categoría añadida"
        });

        this.$emit("close");
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor vuelva a intentarlo"
        });

        console.log(err);
      }
    },

    onCancel() {
      this.category = {};

      this.isEditing = false;
      this.$emit("close");
    }
  },

  async mounted() {
    if (this.categoryToEdit) {
      this.isEditing = true;
      Object.assign(this.category, this.categoryToEdit);
    }
  }
};
</script>

<style scoped></style>
