<template>
  <section class="hero">
    <div class="hero__inner">
      <h1>
        <slot name="title"></slot>
      </h1>
      <p>
        <slot name="description"></slot>
      </p>
    </div>

    <logo size="sm" :color="'white'" />
  </section>
</template>

<script>
import Logo from "./Logo.vue";

export default {
  name: "ViewHero",

  components: { Logo }
};
</script>

<style scoped>
.hero {
  position: relative;
  flex-shrink: 0;
  text-align: center;
  background-color: var(--grayColor1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  background-position: center;
  background-size: cover;
}

.hero__inner {
  background-color: rgba(000, 000, 000, 0.5);
  padding: var(--lengthLg2) var(--lengthMd3);
}

.hero h1 {
  color: white;
  font-size: var(--lengthLg2);
}

.hero p {
  max-width: 75ch;
  margin: var(--lengthSm2) auto 0;
  color: var(--grayColor1);
  font-size: var(--lengthMd1);
}

.logo-container {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--lengthMd1);
  display: flex;
  align-items: flex-end;
}
</style>
