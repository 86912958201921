<template>
  <validation-observer tag="div" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div class="steps">
        <span
          v-for="(step, index) in steps"
          :key="index"
          class="step"
          :class="{ 'step--active': currentStep >= index }"
        >
          {{ `${index + 1}. ${step}` }}
        </span>
      </div>

      <div class="container">
        <slot></slot>
      </div>

      <div class="buttons">
        <custom-button
          type="button"
          variant="secondary"
          @click="$emit('cancel')"
        >
          Cancelar
        </custom-button>

        <custom-button
          :disabled="currentStep === 0"
          type="button"
          variant="secondary"
          @click="previousStep"
        >
          <font-awesome-icon slot="icon" :icon="icons.previous" size="xs" />
          Anterior
        </custom-button>

        <custom-button type="submit" variant="primary" :disabled="invalid">
          <template v-if="currentStep === steps.length - 1">
            {{ submitButtonText }}
            <font-awesome-icon
              slot="append-icon"
              :icon="icons.send"
              size="xs"
            />
          </template>
          <template v-else>
            Siguiente
            <font-awesome-icon
              slot="append-icon"
              :icon="icons.next"
              size="xs"
            />
          </template>
        </custom-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import { ValidationObserver } from "vee-validate";

import CustomButton from "./CustomButton.vue";

export default {
  name: "MultiStepForm",

  model: {
    prop: "currentStep",
    event: "changeStep"
  },

  components: { CustomButton, ValidationObserver },

  props: {
    steps: Array,
    currentStep: Number,
    submitButtonText: {
      type: String,
      default: "Enviar"
    }
  },

  data() {
    return {
      icons: {
        previous: faLongArrowAltLeft,
        next: faLongArrowAltRight,
        send: faPaperPlane
      }
    };
  },

  methods: {
    previousStep() {
      if (this.currentStep - 1 < 0) return;
      this.$emit("changeStep", this.currentStep - 1);
    },

    onSubmit(e) {
      if (this.currentStep === this.steps.length - 1) this.$emit("submit", e);
      else this.$emit("changeStep", this.currentStep + 1);
    }
  }
};
</script>

<style scoped>
.steps {
  display: flex;
  align-items: center;
  gap: var(--lengthSm2);
}

.step {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lengthSm1);
  color: var(--grayColor2);
  font-weight: var(--medium);
}

.step::after {
  content: "";
  width: 100%;
  height: var(--lengthSm1);
  background-color: currentColor;
}

.step--active {
  color: var(--mainColor1);
}

.container {
  margin: var(--lengthLg1) 0;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  padding: var(--lengthMd2);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--lengthMd1);
}

.buttons .btn:first-child {
  margin-right: auto;
}
</style>
