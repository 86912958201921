<template>
  <router-link
    v-if="to"
    :to="to"
    :class="btnVariant"
    class="btn"
    :style="{ width: fullWidth ? '100%' : 'auto' }"
  >
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </router-link>

  <a
    v-else-if="href"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    :class="btnVariant"
    class="btn"
    :style="{ width: fullWidth ? '100%' : 'auto' }"
  >
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </a>

  <button
    v-else
    @click="$emit('click')"
    :type="type"
    :disabled="disabled"
    :class="btnVariant"
    class="btn"
    :style="{ width: fullWidth ? '100%' : 'auto' }"
  >
    <slot name="icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </button>
</template>

<script>
export default {
  name: "CustomButton",

  props: {
    to: String,
    href: String,
    variant: String,
    type: {
      default: "button",
      type: String
    },
    disabled: Boolean,
    fullWidth: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    btnVariant() {
      switch (this.variant) {
        case "text":
          return "btn--text";
        case "secondary":
          return "btn--secondary";
        case "ghost":
          return "btn--ghost";
        case "danger":
          return "btn--danger";
        default:
          return "btn--primary";
      }
    }
  }
};
</script>

<style scoped>
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  padding: 0.75rem var(--lengthSm3);
  font-size: var(--lengthSm3);
  font-weight: var(--medium);
  border-radius: 4px;
  transition: transform 300ms;
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn:active:not(:disabled) {
  transform: translateY(1px);
}

.btn--primary {
  color: white;
  background-color: var(--mainColor1);
  transition: background-color 300ms;
}

.btn--primary:hover:not(:disabled) {
  background-color: var(--mainColor2);
}

.btn--secondary {
  color: var(--mainColor1);
  background-color: white;
  box-shadow: 0 0 1px 1px var(--grayColor2) inset;
  transition: background-color 300ms;
}

.btn--secondary:hover:not(:disabled) {
  background-color: var(--grayColor1);
}

.btn--ghost {
  color: var(--mainColor1);
  box-shadow: 0 0 0 1px currentColor inset;
}

.btn--danger {
  color: var(--dangerColor);
  box-shadow: 0 0 0 1px currentColor inset;
}

.btn--text {
  height: auto;
  color: var(--fontColor2);
  transition: color 300ms;
}

.btn--text:hover:not(:disabled) {
  color: var(--fontColor1);
}
</style>
