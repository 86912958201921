<template>
  <ValidationProvider
    tag="label"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="input"
    :vid="vid"
  >
    <input
      :value="value"
      :type="type"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      class="field"
      :class="{ 'field--invalid': errors.length }"
    />

    <span
      v-if="label"
      class="label"
      :class="{ 'label--invalid': errors.length }"
    >
      {{ `${label || name} ${required ? "*" : ""}` }}
    </span>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "CustomInput",

  components: { ValidationProvider },

  props: {
    value: {
      default: "",
      type: String
    },

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "text"
    },

    vid: String,

    label: String,
    placeholder: String
  }
};
</script>

<style scoped>
.input {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: var(--lengthMd1);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--mainColor1);
}

.label--invalid {
  color: var(--dangerColor);
}

.field {
  max-width: 100%;
  width: 100%;
  padding: var(--lengthMd1) var(--lengthSm2) var(--lengthSm3);
  color: var(--fontColor1);
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
  transition: border-color 300ms;
}

.field:not(.field--invalid):focus {
  border-color: var(--mainColor1);
}

.field--invalid {
  border-color: var(--dangerColor);
}
</style>
