<template>
  <div class="flex">
    <number-input
      label="Mínimo"
      :value="min"
      @input="updateMin"
      :rules="
        `required|positive|numeric|max_value:${
          !isNaN(max) && max > 0 ? max - 1 : 100000
        }`
      "
    />
    <number-input
      label="Máximo"
      :value="max"
      @input="updateMax"
      :rules="
        `required|positive|numeric|min_value:${!isNaN(min) ? min + 1 : 0}`
      "
    />

    <span>Texto: {{ value }}</span>
  </div>
</template>

<script>
import NumberInput from "./NumberInput.vue";

export default {
  name: "RangeInput",

  components: { NumberInput },

  props: {
    value: String
  },

  data: () => ({
    min: 0,
    max: 0
  }),

  methods: {
    updateMin(newValue) {
      if (newValue >= this.max) return;

      this.min = newValue;
      this.emitValue();
    },

    updateMax(newValue) {
      if (newValue <= this.min) return;

      this.max = newValue;
      this.emitValue();
    },

    emitValue() {
      this.$emit("input", `Entre ${this.min} y ${this.max}`);
    }
  }
};
</script>

<style scoped>
.flex {
  align-items: stretch;
  flex-direction: column;
  grid-gap: var(--lengthSm3);
  gap: var(--lengthSm3);
}

span {
  font-weight: var(--medium);
}
</style>
