<template>
  <div class="option">
    <div class="flex justify-between header">
      <span class="label">{{ label }}</span>
      <button type="button" class="button" @click="$emit('delete', $event)">
        <font-awesome-icon icon="times" size="lg" />
      </button>
    </div>

    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Option",

  props: {
    label: String
  }
};
</script>

<style scoped>
.option {
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.header {
  padding: var(--lengthSm3);
  background-color: var(--grayColor1);
  border-top-left-radius: var(--lengthSm1);
  border-top-right-radius: var(--lengthSm1);
  border-bottom: 1px solid var(--grayColor2);
}

.label {
  color: var(--fontColor1);
  font-weight: var(--medium);
}

.content {
  padding: var(--lengthSm3);
}
</style>
