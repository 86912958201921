<template>
  <div>
    <view-hero>
      <template slot="title">Categorías</template>

      <template slot="description">
        En categorías podrás administrar la información y los resultados de la
        mejor manera posible. Puedes incluir información relevante de tu
        organización, con filtros que ayudarán en el proceso de la encuesta de
        clima laboral.
      </template>
    </view-hero>

    <custom-section>
      <template v-if="categories.length">
        <div class="flex justify-between">
          <h2>Categor&iacute;as</h2>

          <custom-button variant="primary" @click="newCategoryModal = true">
            Agregar Categor&iacute;a
            <font-awesome-icon icon="plus" slot="icon" />
          </custom-button>
        </div>

        <div>
          <article
            v-for="category in categories"
            :key="category.id"
            class="card"
          >
            <div>
              <h3>{{ category.name }}</h3>
              <span>{{ categoryInfo(category) }}</span>
            </div>

            <div class="btn-container">
              <custom-button variant="ghost" @click="onEdit(category)">
                <font-awesome-icon icon="pen" slot="icon" />
              </custom-button>
              <custom-button variant="text" @click="onDelete(category.id)">
                <font-awesome-icon icon="trash-alt" slot="icon" />
              </custom-button>
            </div>
          </article>
        </div>
      </template>

      <empty-screen
        v-else
        :icon="emptyScreenIcon"
        @ctaClick="newCategoryModal = true"
      >
        <template slot="title">No tienes Categorías</template>
        <template slot="description">
          Presiona el botón inferior para agregar tu primera categoría.
        </template>
        <template slot="btnText">Añadir Categoría</template>
      </empty-screen>
    </custom-section>

    <modal :isOpen="newCategoryModal" @close="cancelEdit">
      <template slot="title">
        {{ categoryToEdit ? "Editar " : "Nueva " }}Categoría
      </template>

      <edit-category :categoryToEdit="categoryToEdit" @close="cancelEdit" />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { faFilter } from "@fortawesome/free-solid-svg-icons";

import CustomSection from "@/components/CustomSection.vue";
import CustomButton from "@/components/CustomButton.vue";
import Modal from "@/components/Modal.vue";
import EditCategory from "./EditCategory.vue";
import ViewHero from "../components/ViewHero.vue";
import EmptyScreen from "./EmptyScreen.vue";

export default {
  name: "Categories",

  components: {
    CustomButton,
    CustomSection,
    Modal,
    EditCategory,
    ViewHero,
    EmptyScreen
  },

  data: () => ({
    newCategoryModal: false,
    categoryToEdit: null,

    emptyScreenIcon: faFilter
  }),

  computed: {
    ...mapState(["categories", "survey"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions(["fetchCategories", "deleteCategory"]),

    categoryInfo(category) {
      return `Tipo ${category.dataType === "Number" ? "Número" : "Texto"}, ${
        category.options.length
      } opciones`;
    },

    onEdit(category) {
      this.categoryToEdit = JSON.parse(JSON.stringify(category));
      this.newCategoryModal = true;
    },

    cancelEdit() {
      this.categoryToEdit = null;
      this.newCategoryModal = false;
    },

    async onDelete(categoryId) {
      if (!confirm("Estás seguro que quieres eliminar esta categoría?")) return;

      try {
        await this.deleteCategory(categoryId);
        this.setAlert({
          state: "success",
          message: "Categoría eliminada"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor vuelva a intentarlo"
        });
      }
    }
  },

  mounted() {
    this.fetchCategories();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lengthMd1);
  background-color: white;
}

.card:nth-child(odd) {
  background-color: #f7f7f7;
}

span {
  margin-top: var(--lengthSm1);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
}

.card .btn-container {
  margin-left: auto;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}
</style>
